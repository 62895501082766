import config from '../../../config'
import ElementDataSet from '../../../types/ElementDataSet'

export default function getElementDataSet(element?: HTMLElement): ElementDataSet {
  if (!element) return {} as ElementDataSet
  // @ts-ignore
  return Object.fromEntries(
    Object.entries(element.dataset).filter(
      ([k]) => config.ALLOWED_PLACEMENT_ATTRIBUTES.indexOf(k) !== -1
    )
  ) as ElementDataSet
}