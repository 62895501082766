const istestEnv = process.env.ENVIRONMENT === 'test'
const isDevEnv = process.env.ENVIRONMENT === 'development'
const isTestOrDevEnv = istestEnv || isDevEnv

// eslint-disable-next-line no-shadow
export enum Region {
  AP = 'ap',
  EU = 'eu',
  US = 'us',
}

// eslint-disable-next-line no-shadow
export enum CountryCode {
  AT = 'at',
  AU = 'au',
  BE = 'be',
  CA = 'ca',
  CZ = 'cz',
  DE = 'de',
  DK = 'dk',
  ES = 'es',
  FR = 'fr',
  GB = 'gb',
  GR = 'gr',
  IE = 'ie',
  IT = 'it',
  MX = 'mx',
  NL = 'nl',
  NO = 'no',
  NZ = 'nz',
  PL = 'pl',
  PT = 'pt',
  SE = 'se',
  SK = 'sk',
  US = 'us',
}

export default {
  TRACKING: {
    SCRIPT_BOOTSTRAPING_START: 'SCRIPT_BOOTSTRAPING_START',
    SCRIPT_BOOTSTRAPING_END: 'SCRIPT_BOOTSTRAPING_END',
    START_AD_LOADING: 'START_AD_LOADING',
    FRONTEND_EVENTS_CLIENT_ID: 'osm-client-script',
  },
  CLIENT_ID_LENGTH: 36,
  AD_RPC_NAMESPACE: 'k-ad',
  COOKIE_SESSION_ID_NAME: 'ku1-sid',
  COOKIE_VISITOR_KEY_NAME: 'ku1-vid',
  COOKIE_EXPIRATION_DAYS_COUNT: 400,
  KLARNA_FONTS_URL: 'https://x.klarnacdn.net/onsite-messaging/fonts/v1.2/fonts.css',
  CONSOLE_MESSAGE_PREFIX: 'Klarna On-site Messaging:',
  PLACEMENT_TAG_NAME: 'klarna-placement',
  MERCHANT_RPC_NAMESPACE: 'k-merchant',
  HOSTED_INTERSTITIAL_PROTOCOLS: isTestOrDevEnv ? ['http:', 'https:'] : ['https:'],
  HOSTED_INTERSTITIAL_ORIGINS:
  isTestOrDevEnv ? ['klarna.net', 'klarnaservices.com', 'pre-purchase.url', 'windows.net']
    : ['klarnaservices.com', 'klarna.net', 'klarna.com', 'klarnademo.com'],
  KLARNA_CDN_URL: 'https://x.klarnacdn.net',
  TERMS_OF_SERVICE_CDN: 'https://cdn.klarna.com',
  DOMAINS_TO_HIDE_TEST_DRIVE: [
    'www.klarna.com', 'www.klarnademo.com', 'demo.klarna.dev'
  ],
  ALLOWED_PLACEMENT_ATTRIBUTES: [
    'trackingId',
    'customPaymentMethodIds',
    'messagePrefix',
    'key',
    'locale',
    'theme',
    'purchaseAmount',
    'purchase_amount',
    'total_amount',
    'totalAmount',
    'inline',
  ],
  SUPPORTED_LOCALES: process.env.SUPPORTED_LOCALES,
  CMA_BASE_URL: {
    test: {
      ap: 'https://js.playground.klarna.com/na/cma',
      eu: 'https://js.playground.klarna.com/na/cma',
      us: 'https://js.playground.klarna.com/na/cma',
    },
    development: {
      ap: 'https://x.nonprod.us1.js.klarna.net/oc/cma',
      eu: 'https://x.nonprod.us1.js.klarna.net/eu/cma',
      us: 'https://x.nonprod.us1.js.klarna.net/na/cma',
    },
    production: {
      ap: 'https://js.klarna.com/oc/cma',
      eu: 'https://js.klarna.com/eu/cma',
      us: 'https://js.klarna.com/na/cma',
    },
    playground: {
      ap: 'https://js.playground.klarna.com/oc/cma',
      eu: 'https://js.playground.klarna.com/eu/cma',
      us: 'https://js.playground.klarna.com/na/cma',
    },
    staging: {
      ap: 'https://x.nonprod.us1.js.klarna.net/oc/cma',
      eu: 'https://x.nonprod.us1.js.klarna.net/eu/cma',
      us: 'https://x.nonprod.us1.js.klarna.net/na/cma',
    }
  },
  EVT_BASE_URL: {
    test: {
      ap: 'https://evt-na.playground.klarnaservices.com',
      eu: 'https://evt-na.playground.klarnaservices.com',
      us: 'https://evt-na.playground.klarnaservices.comA',
    },
    development: {
      ap: 'DUMMY_EVT_URL',
      eu: 'DUMMY_EVT_URL',
      us: 'DUMMY_EVT_URL',
    },
    production: {
      ap: 'https://evt-oc.klarnaservices.com',
      eu: 'https://evt-eu.klarnaservices.com',
      us: 'https://evt-na.klarnaservices.com',
    },
    playground: {
      ap: 'https://evt-oc.playground.klarnaservices.com',
      eu: 'https://evt-eu.playground.klarnaservices.com',
      us: 'https://evt-na.playground.klarnaservices.com',
    },
    staging: {
      ap: 'https://frontend-event-router-ap.staging.c2c.klarna.net',
      eu: 'https://frontend-event-router-eu.staging.c2c.klarna.net',
      us: 'https://frontend-event-router-us.staging.c2c.klarna.net',
    }
  },
  WEBSDK_BASE_URL: {
    test: 'https://js.klarna.com/web-sdk/v1/klarna.js',
    development: 'https://s3.int.klarna.net/web-sdk/v1/klarna.js',
    production: 'https://js.klarna.com/web-sdk/v1/klarna.js',
    playground: 'https://js.klarna.com/web-sdk/v1/klarna.js',
    staging: 'https://s3.int.klarna.net/web-sdk/v1/klarna.js',
  },
  DEPRECATED_PLACEMENT_KEYS: [
    'top-strip-promotion-standard',
    'credit-promotion-standard',
    'credit-promotion-small',
    'info-page-standard',
    'info-page-auto-size'
  ],
  ALLOWED_PLACEMENT_KEYS: [
    'credit-promotion-auto-size',
    'credit-promotion-badge',
    'credit-promotion-inline',
    'credit-promotion-small',
    'credit-promotion-standard',
    'footer-promotion-auto-size',
    'homepage-promotion-box',
    'homepage-promotion-tall',
    'homepage-promotion-wide',
    'info-page',
    'info-page-auto-size',
    'info-page-inline',
    'info-page-standard',
    'sidebar-promotion-auto-size',
    'top-strip-promotion-auto-size',
    'top-strip-promotion-badge',
    'top-strip-promotion-standard'
  ]
}