// performance.getEntriesByName is not supported by Opera.
export default function getMarkedEntry(name: string): Pick<PerformanceEntry, 'startTime'> {
  const emptyEntry: Pick<PerformanceEntry, 'startTime'> = { startTime: undefined }
  let entry: Pick<PerformanceEntry, 'startTime'>

  if (performance && performance.getEntriesByName) {
    // eslint-disable-next-line prefer-destructuring
    entry = performance.getEntriesByName(name)[0]
  } if (performance.getEntriesByType) {
    // eslint-disable-next-line prefer-destructuring
    entry = performance.getEntriesByType('mark').filter((e: PerformanceEntry) => e.name === name)[0]
  }

  return entry || emptyEntry
}