import { getRegionFromLocale } from '../getRegionFromLocale/getRegionFromLocale'
import config from '../../config'
import getDefaultEnv from '../getDefaultEnv/getDefaultEnv'

export default function getEventsUrl(locale: string): string {
  const region = getRegionFromLocale(locale)
  /* On stand-alone interestitials (rendered on iframe without lib.js)
  window.Klarna.OnsiteMessaging?.environment do not exist so we need fallback */
  const env = window.Klarna?.OnsiteMessaging?.environment || getDefaultEnv()
  return config.EVT_BASE_URL[env][region] || process.env.EVT_BASE_URL
}