import { PerformanceTrackingMark } from '../trackingTypes'

export default function getAdRequestMetrics(adUrl: string): PerformanceTrackingMark {
  let resource = performance.getEntriesByType('resource')
    .filter(entry => entry.name === adUrl)[0] as PerformanceResourceTiming
  resource ||= {} as PerformanceResourceTiming

  return {
    start: resource.fetchStart,
    end: resource.responseEnd,
    size: resource.transferSize
  }
}