import config from '../../config'
import getMarkedEntry from '../getMarkedEntry/getMarkedEntry'

export default function getAdLoadedMetrics(numberOfRefreshPlacements: number, endingMarkId: string) {
  const startMarkId = `${config.TRACKING.START_AD_LOADING}_${numberOfRefreshPlacements}`
  const end = getMarkedEntry(endingMarkId)
  const start = getMarkedEntry(startMarkId)
  return {
    start: start.startTime,
    end: end.startTime
  }
}