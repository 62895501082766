import { Subject } from '../../services/observable'

/**
 * Interface for event pushed on Data Layer object
 */
export interface Event {
  eventName: string
  time?: Date
}

/**
 * Listener for Data Layer solution
 *
 * Data Layer is public interface for ad markup
 * that merchant can use to send additional information
 * or inform about changes in application via events.
 */
export class DomReadyEventSource extends Subject<Event> {
  private isReady = false

  public constructor(private win: Window) {
    super()
  }

  public listen(): this {
    if (this.win.document.readyState !== 'loading') {
      this.execDomReady()
    } else {
      this.win.document.addEventListener('DOMContentLoaded', () => this.execDomReady())
      this.win.addEventListener('load', () => this.execDomReady())
    }

    return this
  }

  public execDomReady() {
    if (this.isReady) {
      return
    }

    this.isReady = true

    this.notify({
      eventName: 'dom-ready',
      time: new Date()
    })
  }
}
