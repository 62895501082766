import config from '../../config'

const logStyle = 'color: black; background-color: #FFB3C7; padding: 2px;'

export function error(message: string) {
  console.error(`%c${config.CONSOLE_MESSAGE_PREFIX} ${
    message
  }`, logStyle)
}

export function warn(message: string) {
  console.warn(`%c${config.CONSOLE_MESSAGE_PREFIX} ${
    message
  }`, logStyle)
}