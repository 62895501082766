import getMetricsData from '../getMetricsData/getMetricsData'
import { AdLoadedParams } from '../trackingTypes'
import getFrontendEventsClient from '../getFrontendEventsClient/getFrontendEventsClient'
import { generateSessionId } from '../../utils/generateSessionId/generateSessionId'
import getEventsUrl from '../../utils/getEventsUrl/getEventsUrl'
import LoadMetricsEvent from '../../types/LoadMetricsEvent'

export default function sendLoadMetricsEvent(params: AdLoadedParams) {
  const { locale } = params
  const eventsUrl = getEventsUrl(locale)
  const frontendEventsClient = getFrontendEventsClient(eventsUrl)

  const endingMarkId = `ad_loaded_${generateSessionId()}`
  performance.mark(endingMarkId)
  const metrics = getMetricsData(params, endingMarkId)

  const data: LoadMetricsEvent = {
    uci: window.Klarna.OnsiteMessaging.clientId,
    client_id: window.Klarna.OnsiteMessaging.clientId,
    ...metrics
  }

  // remove undefined values from object
  Object.keys(data).forEach(key => data[key] === undefined && delete data[key])

  frontendEventsClient.event('load_time_metrics', data)
}