import sendShadowDomUnsupportedEvent from '../../../tracking/sendShadowDomUnsupportedEvent/sendShadowDomUnsupportedEvent'
import isValidPlacement from '../../../utils/isValidPlacement/v2/isValidPlacement'
import { shouldUseShadowDom } from '../../../utils/shouldUseShadowDom/shouldUseShadowDom'
import processInline from '../../processInline/v2/processInline'
import processShadowDom from '../../processShadowDom/v2/processShadowDom'

export default function processPlacements({
  element, forceReload, numberOfRefreshPlacements
}: any) {
  const params = { ...element.dataset } as any

  if (!isValidPlacement(element)) {
    return
  }

  const isInlinePlacement = params.inline !== undefined && (params.inline === '' || params.inline.toLowerCase() === 'true')

  if (isInlinePlacement) {
    processInline({ element, numberOfRefreshPlacements, forceReload })
    return
  }

  // @TODO remove after moving all iframe merchants to shadow-dom
  if (shouldUseShadowDom() && element.attachShadow) {
    processShadowDom({
      element,
      numberOfRefreshPlacements,
      forceReload
    })
    return
  }

  sendShadowDomUnsupportedEvent()
}