import config from '../../../config'
import { markStartAdLoading } from '../../../tracking/trackExecutionTime/trackExecutionTime'
import { buildHttpQuery } from '../../../utils/url/url'
import processPlacements from '../../processPlacements/v2/processPlacements'

/* eslint-disable no-unused-vars */
export default function loadAds({ forceReload = false, numberOfRefreshPlacements = 0 } = {}) {
  markStartAdLoading(numberOfRefreshPlacements)
  // @ts-ignore
  const placements = document.getElementsByTagName(config.PLACEMENT_TAG_NAME)

  let duplicatesPlacements: HTMLElement[] = []
  const uniqueQuery: any = []

  const uniquePlacements: HTMLElement[] = Object.keys(
    Array
      .prototype
      .slice
      .call(placements)
      .reduce((accumulator: any, placement: HTMLElement) => {
        if (!uniqueQuery[buildHttpQuery(placement.dataset)]) {
          uniqueQuery[buildHttpQuery(placement.dataset)] = placement
        } else {
          duplicatesPlacements = [...duplicatesPlacements, placement]
        }
        return uniqueQuery
      }, uniqueQuery)
  )
    .map((i: string) => uniqueQuery[i])

  const allPlacements = uniquePlacements.concat(duplicatesPlacements)

  for (const element of allPlacements) {
    processPlacements({
      element,
      numberOfRefreshPlacements,
      forceReload
    })
  }
}