import ScriptDetectionV2 from './utils/ScriptDetection/v2/ScriptDetectionV2'
import { DomReadyEventSource } from './utils/domReady/DomReadyEventSource'
import { createVisitor } from './services/visitor'
import { markEndExecution, markStartExecution } from './tracking/trackExecutionTime/trackExecutionTime'
import loadAds from './adsProcessing/loadAd/v2/loadAdsV2'
import { createSession } from './utils/session/session'
import { warn } from './utils/logger/logger'
import KlarnaOnsiteService, { OnsiteMessaging } from './utils/KlarnaOnsiteService/v2/KlarnaOnSiteService'

markStartExecution()

export const merchantScript = new ScriptDetectionV2()

if (merchantScript.clientId) {
  // eslint-disable-next-line
  /* global __webpack_public_path__:writable */
  if (process.env.ENVIRONMENT !== 'development' && process.env.ENVIRONMENT !== 'test') {
    __webpack_public_path__ = `https://${merchantScript.host}/`
  }

  if (!(`${process.env.ENVIRONMENT}`.indexOf('production') > -1)) {
    warn(`You are using a non-production suitable integration of On-site Messaging.
      \n If this is your production environment please check https://docs.klarna.com/on-site-messaging/osm-go-live-check-list/`)
  }

  // This exposed object needs to be deprecated, supported here for backwards compatibility.
  window.KlarnaOnsiteService = KlarnaOnsiteService
  window.kudt = KlarnaOnsiteService

  window.Klarna = window.Klarna || { OnsiteMessaging }
  window.Klarna.OnsiteMessaging = OnsiteMessaging
  window.Klarna.OnsiteMessaging.clientId = merchantScript.clientId
  window.Klarna.OnsiteMessaging.environment = merchantScript.environment
  window.Klarna.OnsiteMessaging.originUrl = merchantScript.originUrl
  window.Klarna.OnsiteMessaging.host = merchantScript.host
  window.Klarna.OnsiteMessaging.integrationVersion = 'v2'
  window.Klarna.OnsiteMessaging.loaded = window.Klarna.OnsiteMessaging.loaded || false

  const domReadyEventSource = new DomReadyEventSource(window)

  warn(
    `The Klarna library you are utilizing will be deprecated end of 2024.\n
    We strongly recommend updating your integration for continued support and enhanced features.\n
    For migration details, please visit: https://docs.klarna.com/on-site-messaging/migrate-to-new-klarna-websdk/"`
  )

  createVisitor()
  createSession()

  if (!window.Klarna.OnsiteMessaging.loaded) {
    window.Klarna.OnsiteMessaging.loaded = true

    domReadyEventSource.subscribe(() => {
      loadAds({})
    })

    domReadyEventSource.listen()
  }

  markEndExecution()
}
