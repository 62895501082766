import { CountryCode, Region } from '../../types/Localization'

export type RegionMapping = {
  [key in Lowercase<CountryCode>]: Lowercase<Region>
}

export const regionMapping: RegionMapping = {
  at: 'eu',
  au: 'ap',
  be: 'eu',
  ca: 'us',
  ch: 'eu',
  cz: 'eu',
  hu: 'eu',
  de: 'eu',
  dk: 'eu',
  es: 'eu',
  fi: 'eu',
  fr: 'eu',
  gb: 'eu',
  gr: 'eu',
  ie: 'eu',
  it: 'eu',
  mx: 'us',
  nl: 'eu',
  no: 'eu',
  nz: 'ap',
  pl: 'eu',
  pt: 'eu',
  ro: 'eu',
  se: 'eu',
  sk: 'eu',
  us: 'us',
}
