type InterstitialType = 'learn-more'

async function loadDialog(element: any, interstitialProps: any, target: any, type: InterstitialType) {
  const interstitial = await import(
    /* webpackChunkName: "osm-interstitial" */
    './index'
  )

  interstitial.showDialog({
    ...element.dataset,
    ...interstitialProps,
    previousElement: target,
    type,
  })
}

export default function addShadowDomInterstitial(element: any): void {
  let buttons

  if (element.kpsr) {
    buttons = [
      ...Array.from(element.kpsr.firstElementChild.querySelectorAll('a')) as HTMLElement[],
      ...Array.from(element.kpsr.firstElementChild.querySelectorAll('[onclick]')) as HTMLElement[],
    ]
  } else {
    buttons = [
      ...Array.from(element.querySelectorAll('[onclick]')) as HTMLButtonElement[],
      ...Array.from(element.querySelectorAll('a')) as HTMLElement[],
    ]
  }

  buttons.forEach((button: HTMLButtonElement) => {
  // Placements without buttons e.g. footer
    if (!button || !button.onclick) return

    const onClickHandler = button.onclick.toString()

    let type: InterstitialType

    let interstitialProps = {
      showButtons: true,
      showBackground: false,
      clientId: window.Klarna.OnsiteMessaging.clientId
    }

    // We override the click handler return form BE to not open the interstitial on iframe
    if (onClickHandler.indexOf('learn-more') > 0) {
      type = 'learn-more'

      // Get tier props from interstitial token
      const regex = /#(.*?)'/
      const [, token] = regex.exec(onClickHandler)
      const tierProps = JSON.parse(window.atob(token))
      interstitialProps = {
        ...interstitialProps,
        ...tierProps,
      }
    } else {
      return
    }

    // Remove onclick handler that opens iframe, generated by osm-ad-server (still needed for BE integration)
    button.removeAttribute('onclick')

    // Add a new onclick handler that will open the interstitial in shadow DOM
    button.addEventListener('click', (e: any) => {
      e.preventDefault()
      loadDialog(element, interstitialProps, e.target, type)
      // make sure we dont display both shadow-dom and shadow-dom interstitial
      setTimeout(() => {
        document.querySelector('.klarna-upstream-interstitial-frame')?.remove()
      }, 100)
    })
  })
}