import { ImpressionEvent, TrackingParamsResponse } from '../../types/ImpressionEvent'
import RenderingType from '../../types/RenderingType'
import getElementDataSet from '../../utils/getElementDataSet/v2/getElementDataSet'
import getEventsUrl from '../../utils/getEventsUrl/getEventsUrl'
import getFrontendEventsClient from '../getFrontendEventsClient/getFrontendEventsClient'

export default function sendTrackingImpression(
  params: TrackingParamsResponse,
  element: HTMLElement,
  renderingType: RenderingType
) {
  const {
    key, trackingId, locale, messagePrefix
  } = getElementDataSet(element)
  // Remove https:// from the script source as it results in a 403
  const scriptSource = document.querySelector('script[data-client-id]')?.getAttribute('src')?.split('://')[1]
  const [language, countryCode] = locale.replace('-', '_').split('_')

  const trackingParams: ImpressionEvent = {
    ...params,
    iv: window.Klarna.OnsiteMessaging.integrationVersion,
    d: key,
    ti: trackingId,
    h: `${language}`.toUpperCase(),
    i: `${countryCode}`.toUpperCase(),
    n: window.location.host,
    ae: window.location.pathname,
    g: window.Klarna.OnsiteMessaging.clientId as string,
    mpf: messagePrefix,
    rt: renderingType,
    ab: 'osm-frontends',
    ss: scriptSource
  }

  const eventsUrl = getEventsUrl(locale)
  const frontendEventsClient = getFrontendEventsClient(eventsUrl)

  // remove undefined values from object
  Object.keys(trackingParams).forEach(k => trackingParams[k] === undefined && delete trackingParams[k])

  // 50%
  if (Math.random() < 0.5 || process.env.ENVIRONMENT === 'test') {
    frontendEventsClient.event('b', trackingParams)
  }

  // 10%
  if (Math.random() < 0.1 || process.env.ENVIRONMENT === 'test') {
    frontendEventsClient.event('aggr_b', trackingParams)
  }
}
