import { AdLoadedParams } from '../trackingTypes'
import getScriptLoadedMetrics from '../getScriptLoadedMetrics/getScriptLoadedMetrics'
import { getScriptBootstrappedMetrics } from '../trackExecutionTime/trackExecutionTime'
import getAdRequestMetrics from '../getAdRequestMetrics/getAdRequestMetrics'
import getAdLoadedMetrics from '../getAdLoadedMetrics/getAdLoadedMetrics'
import { LoadMetrics } from '../../types/LoadMetricsEvent'

export default function getMetricsData(params: AdLoadedParams, endingMarkId: string): LoadMetrics {
  const scriptLoadedMetrics = getScriptLoadedMetrics()
  const scriptBootstrappedMetrics = getScriptBootstrappedMetrics()
  const adRequestMetrics = getAdRequestMetrics(params.adUrl)
  const adLoadedMetrics = getAdLoadedMetrics(params.numberOfRefreshPlacements, endingMarkId)

  const metrics = {
    script_loaded_start: scriptLoadedMetrics.start,
    script_loaded_end: scriptLoadedMetrics.end,
    script_bootstrapped_start: scriptBootstrappedMetrics.start,
    script_bootstrapped_end: scriptBootstrappedMetrics.end,
    message_request_start: adRequestMetrics.start,
    message_request_end: adRequestMetrics.end,
    message_size: adRequestMetrics.size,
    message_loaded_start: adLoadedMetrics.start,
    message_loaded_end: adLoadedMetrics.end,
    number_of_refresh_placement: params.numberOfRefreshPlacements,
    rendering_type: params.renderingType,
    placement_key: params.placementKey,
  }

  return metrics
}