import { ShadowDomUnSupportEvent } from '../../types/ShadowDomUnSupportEvent'
import getFrontendEventsClient from '../getFrontendEventsClient/getFrontendEventsClient'

// To not log to many events we sample and trigger it for 2% of the cases
const SAMPLING_RATE = 0.02

export default function sendShadowDomUnsupportedEvent() {
  const trackingParams: ShadowDomUnSupportEvent = {
    clientId: window.Klarna.OnsiteMessaging.clientId,
    host: window.location.host,
    path: window.location.pathname
  }

  const randomNumber = Math.random()

  if (randomNumber < SAMPLING_RATE) {
    getFrontendEventsClient().event('no_shadow_dom_support', { ...trackingParams })
  }
}