import getDefaultEnv from '../../getDefaultEnv/getDefaultEnv'
import getScriptElement from './getScriptElement/getScriptElement'

const HOST_REGEX = /((https?):|)\/\/(.*)\/lib.js/

export default class ScriptDetectionV2 {
  protected element: HTMLScriptElement

  public constructor() {
    this.element = this.element || getScriptElement()
  }

  public get host() {
    const matches = this.element?.src.match(HOST_REGEX)

    if (!matches) {
      return null
    }

    const host = matches[3]

    return host || ''
  }

  public get originUrl() {
    if (!this.element) {
      return undefined
    }

    const protocol = 'https'

    return `${protocol}://${this.host}`
  }

  public get clientId() {
    return this.element?.getAttribute('data-client-id') || null
  }

  public get environment() {
    return (this.element?.getAttribute('data-environment') || getDefaultEnv()) as Window['Klarna']['OnsiteMessaging']['environment']
  }
}