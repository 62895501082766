import sendLoadMetricsEvent from '../../../tracking/sendLoadMetrics/sendLoadMetrics'
import sendTrackingImpression from '../../../tracking/sendTrackingImpression/sendTrackingImpression'
import appendTestBadge from '../../../utils/testDriveBadge/testDriveBadge'
import hasFontsLink from '../hasFontsLink/hasFontsLink'
import addShadowDomInterstitial from '../../../interstitials/shadowDomInterstitial/addShadowDomInterstitial'
import getElementDataSet from '../../../utils/getElementDataSet/v2/getElementDataSet'
import checkStylingIssues from '../../../utils/checkStylingIssues/checkStylingIssues'
import config from '../../../config'
import { AdResponse } from '../../../types/AdResponse'

export default function processResponse(url: string, element: any, response: AdResponse, numberOfRefreshPlacements: number) {
  if (!hasFontsLink(element)) {
    // @font-face declarations inside Shadow DOM works only in Webkit browsers (Safari)
    // Therefore we need to load the fonts outside shadow root
    // https://github.com/mdn/interactive-examples/issues/887
    // https://github.com/w3c/csswg-drafts/issues/1995
    const klarnaFontsLink = window.document.createElement('link')
    klarnaFontsLink.rel = 'stylesheet'
    klarnaFontsLink.href = config.KLARNA_FONTS_URL
    element.prepend(klarnaFontsLink)
  }

  if (!element.wrapper) {
    const container = window.document.createElement('div')
    container.style.height = 'auto'
    element.wrapper = container
    element.prepend(container)
  }

  if (!element.kpsr) {
    element.kpsr = element.wrapper.attachShadow({ mode: 'open' })
    // prevent click event in shadow dom to bubble outside of it.
    element.kpsr.addEventListener('click', (e: any) => {
      e.stopPropagation()
    })
    // set all css properties to initial values to avoid inheriting through shadow dom boundary
    const container = window.document.createElement('div')
    container.style.all = 'initial'
    element.kpsr.prepend(container)
  }

  if (!response || !response.code) {
    element.wrapper.style.display = 'none'
    element.kpsr.firstElementChild.innerHTML = ''
    element.wrapper.style.maxWidth = ''
    element.wrapper.style.width = ''

    if (!response) {
      return
    }
  } else {
    appendTestBadge(element)
    const options = response.placementOptions
    element.kpsr.firstElementChild.innerHTML = response.code

    if (options && options.width) {
      if (options.widthUnit === 'px') {
        // For the fixed size placements it's needed to limit their width
        // but also allow them to be as wide as possible, i.e. in case if they are put
        // inside a flex container with justify-content: center
        element.wrapper.style.maxWidth = `${options.width}${options.widthUnit}`
        element.wrapper.style.width = '100%'
      } else if (options.widthUnit === '%') {
        // For percent-based width there's no need to set a specific value as a limitation
        element.wrapper.style.width = `${options.width}${options.widthUnit}`
      } else {
        // Set 100% as a fallback
        element.wrapper.style.width = '100%'
      }
    } else {
      // Set 100% as a fallback
      element.wrapper.style.width = '100%'
    }

    element.wrapper.style.display = 'inline-block'

    if (response.customFont) {
      element.insertAdjacentHTML('afterbegin', response.customFont)
    }
  }

  const dataSet = getElementDataSet(element)
  addShadowDomInterstitial(element)

  if (response.impressionTrackerParams) {
    sendTrackingImpression(response.impressionTrackerParams, element, 'shadow-dom')
  }

  if (response.trackLoadTime) {
    sendLoadMetricsEvent({
      locale: dataSet.locale,
      numberOfRefreshPlacements,
      adUrl: url,
      renderingType: 'shadow-dom',
      placementKey: element.dataset.key || element.dataset.id
    })
  }

  /* Check that the merchant respects styling
    timeout of 2sec to be as sure as possible that response
    and css styling has been loaded */
  setTimeout(() => checkStylingIssues(element), 2000)
}