import getElementDataSet from '../../../../utils/getElementDataSet/v2/getElementDataSet'
import { buildHttpQuery } from '../../../../utils/url/url'
import cookies from '../../../../utils/cookies/cookies'
import config from '../../../../config'
import getBaseUrl from '../../../../utils/getBaseUrl/getBaseUrl'
import { isLegacyOSMClientId } from '../../../../utils/isLegacyOSMClientId/isLegacyOSMClientId'

export default function getUrl(element: HTMLElement): string {
  const {
    key, locale, purchaseAmount, messagePrefix, customPaymentMethodIds, ...nonObfuscatedAttributes
  } = getElementDataSet(element)
  const sessionId = cookies.getValue(config.COOKIE_SESSION_ID_NAME)

  const version = isLegacyOSMClientId(window.Klarna.OnsiteMessaging.clientId) ? 'v3' : 'v4'

  const params = {
    ver: process.env.APP_VERSION,
    b: sessionId,
    d: key,
    e: locale,
    g: window.Klarna.OnsiteMessaging.clientId,
    purchase_amount: purchaseAmount,
    message_prefix: messagePrefix,
    custom_payment_method_ids: customPaymentMethodIds,
    ...nonObfuscatedAttributes
  }

  const baseUrl = getBaseUrl(locale) || process.env.CMA_BASE_URL

  return `${baseUrl}/${version}/s${buildHttpQuery(params)}`
}
