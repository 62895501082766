/**
 * Implementation of Observable pattern
 *
 * Observer is object listening on changes in Subject
 * object
 */
export interface Observer<Event> {
  (event: Event): void
}

/**
 * Implementation of Observable pattern
 *
 * Subject is object observed by others objects
 * and notify about changes on it
 */
export abstract class Subject<Event> {
  protected observers: Observer<Event>[] = []

  public subscribe(observer: Observer<Event>): this {
    this.observers.push(observer)

    return this
  }

  protected notify(event: Event): void {
    this.observers.forEach((observer: Observer<Event>) => {
      observer(event)
    })
  }
}
