import { getRegionFromLocale } from '../getRegionFromLocale/getRegionFromLocale'
import config from '../../config'
import getDefaultEnv from '../getDefaultEnv/getDefaultEnv'

export default function getBaseUrl(path: string): string {
  const breakdown = path.split('/')
  const locale = breakdown[breakdown.length - 1]
  const region = getRegionFromLocale(locale)
  /* On stand-alone interestitials (rendered on iframe without lib.js)
  window.Klarna.OnsiteMessaging?.environment do not exist so we need fallback */
  const env = window.Klarna?.OnsiteMessaging?.environment || getDefaultEnv()
  return config.CMA_BASE_URL[env][region]
}