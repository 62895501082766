import { isLegacyOSMClientId } from '../isLegacyOSMClientId/isLegacyOSMClientId'

export default function request(url: string, forceReload = false): Promise<any> {
  const headers = {
    'Klarna-Client-Type': 'libjs'
  }

  const clientIdIsLegacy = isLegacyOSMClientId(window.Klarna.OnsiteMessaging.clientId)

  if (!clientIdIsLegacy) {
    headers['Authorization'] = `basic ${window.Klarna.OnsiteMessaging.clientId}`
  }

  return fetch(
    url,
    {
      cache: forceReload ? 'reload' : 'default',
      headers,
    }
  )
    .then(r => r?.text?.())
    .then(responseText => (responseText ? JSON.parse(responseText) : null))
}
