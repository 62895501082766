import uuid, { validateUUID } from '../utils/uuid/uuid'
import cookies from '../utils/cookies/cookies'
import config from '../config'

function setVisitorCookie(visitorId: string) {
  const visitorCookieExpireDate = new Date()
  visitorCookieExpireDate.setDate(visitorCookieExpireDate.getDay() + config.COOKIE_EXPIRATION_DAYS_COUNT)
  cookies.save({
    name: config.COOKIE_VISITOR_KEY_NAME,
    value: visitorId,
    expires: visitorCookieExpireDate,
    domain: window.location.hostname
  })
}

export function createVisitor(): string {
  const existingVisitorId = cookies.getValue(config.COOKIE_VISITOR_KEY_NAME)
  const hasValidUUID = validateUUID(existingVisitorId)
  const visitorId = hasValidUUID ? existingVisitorId : uuid()

  setVisitorCookie(visitorId)
  return visitorId
}
