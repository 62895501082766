import config from '../../config'
import getMarkedEntry from '../getMarkedEntry/getMarkedEntry'

const { TRACKING } = config
const { SCRIPT_BOOTSTRAPING_START, SCRIPT_BOOTSTRAPING_END, START_AD_LOADING } = TRACKING

export function markStartExecution() {
  performance.mark(SCRIPT_BOOTSTRAPING_START)
}

export function markEndExecution() {
  performance.mark(SCRIPT_BOOTSTRAPING_END)
}

export function markStartAdLoading(numberOfRefreshPlacements: number) {
  const mark = `${START_AD_LOADING}_${numberOfRefreshPlacements}`
  performance.mark(mark)
}

export function getScriptBootstrappedMetrics() {
  // eslint-disable-next-line compat/compat
  const start = getMarkedEntry(SCRIPT_BOOTSTRAPING_START)[0] || {}
  const end = getMarkedEntry(SCRIPT_BOOTSTRAPING_END)[0] || {}
  return {
    start: start.startTime,
    end: end.startTime
  }
}