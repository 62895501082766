import loadAds from '../../../adsProcessing/loadAd/v2/loadAdsV2'
import * as logger from '../../logger/logger'
import OSMWindowExposedSDK, { ExposedEventType } from '../../../types/OSMWindowExposedSDK'
import EnvironmentStage from '../../../types/EnvironmentStage'

type Event = {
  eventName: string
}

const events: Event[] = []

let numberOfRefreshPlacements = 0

// Exposed as window.KlarnaOnsiteService, to be deprecated
class KlarnaOnsiteService extends Array<Event> {
  public clientId?: string

  public loaded?: boolean

  public originUrl?: string

  public version?: string

  public host?: string

  public sessionId?: string

  pop() {
    return events.pop()
  }

  push(event: any) {
    events.push(event)
    switch (event.eventName) {
      case 'refresh-placements':
        // eslint-disable-next-line max-len
        logger.warn('window.KlarnaOnsiteService.push({ eventName: \'refresh-placements\'}) will be deprecated end of 2023. Please use window.Klarna.OnsiteMessaging.refresh()')
        this.refresh()
        break
      default:
        // eslint-disable-next-line max-len
        logger.warn('window.KlarnaOnsiteService.push will be deprecated end of 2023. Please use functionality exposed by window.Klarna.OnsiteMessaging.')
        break
    }
    return events.length
  }

  refresh() {
    numberOfRefreshPlacements += 1
    loadAds({ numberOfRefreshPlacements })
  }
}

export default new KlarnaOnsiteService()

let eventListenersInitialized = false

// Exposed as window.Klarna.OnsiteMessaging
// eslint-disable-next-line @typescript-eslint/naming-convention
export const OnsiteMessaging: OSMWindowExposedSDK = {
  clientId: undefined,
  loaded: false,
  originUrl: undefined,
  version: process.env.APP_VERSION || 'undefined',
  host: undefined,
  sessionId: undefined,
  numberOfRefreshPlacements: 0,
  integrationVersion: 'v2',
  refresh() {
    this.numberOfRefreshPlacements += 1
    loadAds({ numberOfRefreshPlacements: this.numberOfRefreshPlacements })
  },
  eventListeners: {},
  on(eventType: ExposedEventType, callback: Function) {
    if (['informationalModalOpened', 'informationalModalClosed'].indexOf(eventType) === -1) {
      // eslint-disable-next-line max-len
      logger.warn(`window.Klarna.OnsiteMessaging.on('${eventType}') is not supported. Please use 'informationalModalOpened' or 'informationalModalClosed'`)
      return
    }

    if (!callback || typeof callback !== 'function') {
      logger.warn(`window.Klarna.OnsiteMessaging.on('${eventType}') requires a callback function`)
      return
    }

    this.eventListeners[eventType] = callback

    if (!eventListenersInitialized) {
      document?.addEventListener?.('osm-internal-event', (eventData: any & { detail: { type: string }}) => {
        if (eventData.detail.action === 'show') {
          this.eventListeners['informationalModalOpened']?.()
        }
        if (eventData.detail.action === 'hide') {
          this.eventListeners['informationalModalClosed']?.()
        }
      })
      eventListenersInitialized = true
    }
  },
  environment: (process.env.ENVIRONMENT) as EnvironmentStage,
}