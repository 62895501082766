import request from '../../../utils/request/request'
import getUrl from '../getUrl/v2/getUrl'
import processResponse from '../processResponse/processResponse'

const memoryCache = {}

export default async function processShadowDom({ element, forceReload, numberOfRefreshPlacements }: any) {
  const url = getUrl(element)

  if (element.kpurl !== url) {
    // store the url on the element, so that we can ignore reloads when refresh-placements is called, but url is the same
    element.kpurl = url
    const reqPromise = memoryCache[url] || request(url, forceReload)
    memoryCache[url] = reqPromise
    const data = await reqPromise
    processResponse(url, element, data, numberOfRefreshPlacements)
    delete memoryCache[url]
  }
}
