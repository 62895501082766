import config from '../../config'

/* eslint-disable max-len */
const badgeSvg = () => `\
<svg style="display: block; margin-left: auto; width: 60px; height: 18px;" width="60" height="18" viewBox="0 0 60 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H60V13C60 15.7614 57.7614 18 55 18H5C2.23858 18 0 15.7614 0 13V0Z" fill="#92CF48"/>
    <path d="M9.65007 4.99992H4.32007V6.04992H6.38007V11.9999H7.58007V6.04992H9.65007V4.99992Z" fill="white"/>
    <path d="M11.9071 10.9499V8.78992H14.9071V7.75992H11.9071V6.04992H15.3971V4.99992H10.6971V11.9999H15.4871V10.9499H11.9071Z" fill="white"/>
    <path d="M19.0644 12.0799C20.5444 12.0799 21.6144 11.2799 21.6144 9.97992C21.6144 8.74992 20.8644 8.16992 19.7044 7.97992L18.8544 7.83992C18.0944 7.71992 17.6544 7.43992 17.6544 6.85992C17.6544 6.28992 18.2044 5.91992 18.9744 5.91992C19.7844 5.91992 20.2344 6.24992 20.3444 6.95992L21.4744 6.83992C21.3744 5.63992 20.5544 4.91992 18.9744 4.91992C17.4544 4.91992 16.5144 5.73992 16.5144 6.89992C16.5144 8.08992 17.3144 8.66992 18.3244 8.82992L19.2144 8.97992C20.0144 9.10992 20.4544 9.36992 20.4544 10.0199C20.4544 10.6499 19.8644 11.0299 19.0644 11.0299C18.1344 11.0299 17.6044 10.4599 17.5444 9.76992L16.3544 9.83992C16.4144 11.1799 17.3444 12.0799 19.0644 12.0799Z" fill="white"/>
    <path d="M27.5895 4.99992H22.2595V6.04992H24.3195V11.9999H25.5195V6.04992H27.5895V4.99992Z" fill="white"/>
    <path d="M30.9466 4.99992H28.6366V11.9999H30.9466C33.0266 11.9999 34.3466 10.7599 34.3466 8.49992C34.3466 6.23992 33.0266 4.99992 30.9466 4.99992ZM30.9466 10.9499H29.8466V6.04992H30.9466C32.2866 6.04992 33.1466 6.86992 33.1466 8.49992C33.1466 10.1299 32.2866 10.9499 30.9466 10.9499Z" fill="white"/>
    <path d="M35.5018 11.9999H36.7118V9.15992H38.0718L39.3918 11.9999H40.6918L39.2718 8.95992C40.1018 8.72992 40.6618 8.08992 40.6618 7.05992C40.6618 5.73992 39.7518 4.99992 38.3318 4.99992H35.5018V11.9999ZM36.7118 8.13992V6.04992H38.2418C39.0618 6.04992 39.4618 6.41992 39.4618 7.09992C39.4618 7.78992 39.0618 8.13992 38.2418 8.13992H36.7118Z" fill="white"/>
    <path d="M41.9178 11.9999H43.1278V4.99992H41.9178V11.9999Z" fill="white"/>
    <path d="M46.8433 11.9999H48.0134L50.7634 4.99992H49.4633L47.4333 10.3399L45.3933 4.99992H44.1033L46.8433 11.9999Z" fill="white"/>
    <path d="M52.9423 10.9499V8.78992H55.9423V7.75992H52.9423V6.04992H56.4323V4.99992H51.7323V11.9999H56.5223V10.9499H52.9423Z" fill="white"/>
</svg>\
`
/* eslint-enable max-len */

const testBadgeId = 'test-badge-element-id'

function TestDriveBadge(): HTMLDivElement {
  const div = document.createElement('div')
  div.setAttribute('id', testBadgeId)
  div.setAttribute('aria-hidden', 'true')
  div.style.marginRight = '15px'
  div.style.position = 'absolute'
  div.style.marginLeft = '-70px'
  div.innerHTML = badgeSvg().trim()
  return div
}

function hasTestBadge(element: HTMLElement): boolean {
  const existing = document.getElementById(testBadgeId)
  return element.contains(existing)
}

export default function appendTestBadge(element: HTMLElement, hide = false): HTMLDivElement | null {
  // Display badge only on certain environments and exclude domains that
  if (config.DOMAINS_TO_HIDE_TEST_DRIVE.indexOf(window.location.hostname) === -1
    && window.Klarna?.OnsiteMessaging?.environment === 'playground'
  ) {
    // Make sure test badge is not already attached. This could happen when refreshing placement
    if (hasTestBadge(element)) {
      return null
    }

    const div = TestDriveBadge()
    div.style.display = hide ? 'none' : 'inline-block'
    element.appendChild(div)
    return div
  }

  return null
}