export default function getScriptLoadedMetrics() {
  const url = window.Klarna.OnsiteMessaging.originUrl
  const resource = performance?.getEntriesByType?.('resource')
    .filter(entry => entry.name.indexOf(url) > -1)[0] as PerformanceResourceTiming
  if (resource) {
    return {
      start: resource.fetchStart,
      end: resource.responseEnd
    }
  }

  return {}
}