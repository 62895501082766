import sendLoadMetricsEvent from '../../../tracking/sendLoadMetrics/sendLoadMetrics'
import sendTrackingImpression from '../../../tracking/sendTrackingImpression/sendTrackingImpression'
import appendTestBadge from '../../../utils/testDriveBadge/testDriveBadge'
import { AdResponse } from '../../../types/AdResponse'
import addShadowDomInterstitial from '../../../interstitials/shadowDomInterstitial/addShadowDomInterstitial'

export default function processTextBased(url: string, element: HTMLElement, response: AdResponse, numberOfRefreshPlacements: number) {
  if (!response || !response.code) {
    element.style.display = 'none'
    element.innerHTML = ''
  } else {
    element.innerHTML = response.code
    element.setAttribute('aria-hidden', 'true')
    appendTestBadge(element)
  }

  if (!response) {
    return
  }

  if (response.code) {
    element.style.display = 'inline'
    addShadowDomInterstitial(element)
  }

  if (response.impressionTrackerParams) {
    sendTrackingImpression(response.impressionTrackerParams, element, 'inline')
  }

  if (response.trackLoadTime) {
    sendLoadMetricsEvent({
      locale: element.dataset.locale,
      numberOfRefreshPlacements,
      adUrl: url,
      renderingType: 'inline',
      placementKey: element.dataset.key || element.dataset.id
    })
  }
}