import hasTrackingEnabled from '../../tracking/hasTrackingEnabled/hasTrackingEnabled'
import { generateSessionId } from '../generateSessionId/generateSessionId'

interface Cookie {
  name: string
  value: string
  expires?: Date
  domain?: string
  path?: string
}

const path = '/'
const inMemoryCookies: Cookie[] = []
const cookieSeparator = /; */

const decodeCookieValue = (value: string) => {
  try {
    return decodeURIComponent(value)
  } catch {
    return value
  }
}

export function parseCookieString(rawCookiesString: string) {
  const cookies: Cookie[] = []

  if (!rawCookiesString) {
    return cookies
  }

  const rawCookies = rawCookiesString.split(cookieSeparator)

  for (const rawCookie of rawCookies) {
    const parts = rawCookie.split('=')

    if (parts.length === 2) {
      const name = parts[0].trim()
      let value = parts[1].trim()

      if (value[0] === '"') {
        value = value.slice(1, -1)
      }

      value = decodeCookieValue(value)

      cookies.push({
        name,
        value
      })
    }
  }

  return cookies
}

export function deleteCookie({ name, domain }: Omit<Cookie, 'value'>) {
  document.cookie = `${encodeURIComponent(name)
  }=; expires=Thu, 01 Jan 1970 00:00:00 GMT${
    domain ? `; domain=${domain}` : ''
  }${path ? `; path=${path}` : ''}`
}

export function getCookieDomainCandidates(domain: string) {
  const domainParts = domain.split('.')
  return domainParts.map((domainPart, index) => [domainPart, [...domainParts].slice(index + 1).join('.')].join('.'))
    .reverse()
    .slice(1)
}

export function getAllCookies() {
  return hasTrackingEnabled() ? parseCookieString(window.document.cookie) : inMemoryCookies
}

export function get(name: string): Cookie {
  const allWithName = getAllCookies().filter((cookie: Cookie) => cookie.name === name)
  return allWithName[allWithName.length - 1]
}

export function getValue(name: string): string | undefined {
  const cookie = get(name)

  if (hasTrackingEnabled()) {
    return cookie?.value || generateSessionId()
  }

  return undefined
}

export function save({
  name, value, expires, domain
}: Cookie): void {
  if (hasTrackingEnabled()) {
    let cookieBody = `${name}=${value};path=${path};SameSite=Strict;Secure;`

    if (expires !== undefined) {
      cookieBody += `expires=${expires.toUTCString()};`
    }

    if (domain !== undefined && domain !== 'localhost' && domain !== '') {
      const domainCandidates = getCookieDomainCandidates(domain)

      // iterate all cookie domain candidates (from the most "broader" to the least)
      for (const d of domainCandidates) {
        window.document.cookie = `${cookieBody}domain=.${d}`
        // if the cookie was set, we stop iterating.
        // the code would still work without the if statement below, but this would
        // cause the same cookie to be set for multiple domains (which might make
        // it harder to troubleshoot things)
        if (getValue(name)) {
          break
        }
      }
    } else {
      window.document.cookie = cookieBody
    }
  } else {
    deleteCookie({ name, domain })
    inMemoryCookies.push({
      name, value, path, expires, domain
    })
  }
}

export default {
  save, getValue, get
}