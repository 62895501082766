/* eslint-disable max-len */
import isInteger from 'lodash.isinteger'
import config from '../../../config'
import getElementDataSet from '../../getElementDataSet/v2/getElementDataSet'
import isValidLocale from '../../isValidLocale/isValidLocale'
import * as logger from '../../logger/logger'

const { DEPRECATED_PLACEMENT_KEYS, ALLOWED_PLACEMENT_KEYS } = config

/* Check that data-placement-key is valid */
function hasValidPlacementKey(key: string): boolean {
  let hasValidKey = true

  if (!key) {
    hasValidKey = false
    logger.warn('missing data-key in klarna-placement')
  } else {
    // placement is valid but merchants need to migrate placement keys
    if (DEPRECATED_PLACEMENT_KEYS.includes(key)) {
      logger.warn(`The placement type ${key} will soon be deprecated. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/#deprecated-placement-types`)
    }

    // placement is invalid
    if (!(ALLOWED_PLACEMENT_KEYS.includes(key) || key.includes('custom'))) {
      hasValidKey = false
      logger.warn(`${key} is not a valid placement type. Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/`)
    }
  }

  return hasValidKey
}

/* Check that locale is passed and is valid */
function hasValidLocale(locale: string) {
  const isLocaleValid = isValidLocale(locale)
  if (!locale) {
    logger.warn('missing data-locale in klarna-placement')
    return false
  } if (!isLocaleValid) {
    logger.warn(`invalid data-locale (${locale}) in klarna-placement`)
    return false
  }
  return true
}

/* Check that locale is passed and is valid */
function hasValidAmount(key: string, purchaseAmountValue: string) {
  if (`${key}`.includes('credit')) {
    if (purchaseAmountValue === undefined) {
      logger.warn('missing data-purchase-amount in klarna-placement')
      return false
    }

    if (Number.isNaN(parseFloat(purchaseAmountValue))) {
      logger.warn('invalid purchase-amount in klarna-placement, it should be a number')
      return false
    }

    if (parseFloat(purchaseAmountValue) < 0) {
      logger.warn('invalid purchase-amount in klarna-placement, it should be >= 0')
      return false
    }

    if (`${purchaseAmountValue}`.includes('.') || `${purchaseAmountValue}`.includes(',')) {
      // eslint-disable-next-line max-len
      logger.warn('The data-purchase-amount should be in minor units (e.g. $120.00 should be passed as 12000). Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/placements/#attributes')

      // we should allow amounts with .0, e.g. 3000.0 is valid, 3000.1 is not valid
      if (!isInteger(parseFloat(purchaseAmountValue))) return false
    }
  }

  return true
}

export default function isValidPlacement(element: HTMLElement): boolean {
  const {
    key, locale, purchase_amount, purchaseAmount
  } = getElementDataSet(element)

  const purchaseAmountValue = purchase_amount || purchaseAmount

  return hasValidPlacementKey(key) && hasValidLocale(locale) && hasValidAmount(key, purchaseAmountValue)
}
