import request from '../../../utils/request/request'
import getUrl from '../getUrl/v2/getUrl'
import processTextBased from '../processTextBased/processTextBased'

export default function processInline({
  element, forceReload = false, numberOfRefreshPlacements
}: any) {
  const url = getUrl(element)

  if (element.kpurl !== url) {
    // store the url on the element, so that we can ignore reloads when refresh-placements is called, but url is the same
    element.kpurl = url
    request(url, forceReload)
      .then((data: any) => processTextBased(url, element, data, numberOfRefreshPlacements))
  }
}
