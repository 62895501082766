import frontendEvents from '@klarna/frontend-events'
import { generateSessionId } from '../../utils/generateSessionId/generateSessionId'
import config from '../../config'

export default function getFrontendEventsClient(baseUrl?: string) {
  const frontendEventsConfig = {
    client: config.TRACKING.FRONTEND_EVENTS_CLIENT_ID,
    clientVersion: process.env.APP_VERSION || 'undefined',
    baseUrl: baseUrl || process.env.EVT_BASE_URL,
    sessionId: generateSessionId()
  }
  return frontendEvents({ ...frontendEventsConfig })
}