/* eslint-disable @typescript-eslint/naming-convention */
import sendStylingIssueEvent from '../../tracking/sendStylingIssueEvent/sendStylingIssueEvent'
import { warn } from '../logger/logger'

export const OSM_LOGO_PART_NAME = 'osm-logo'

export const BASE_LOGO_FONT_SIZE = 10
export const BASE_LOGO_FONT_FAMILY = '"Klarna Headline"'
export const BASE_LOGO_FONT_WEIGHT = ['bold', 'bolder', '700']

function setImportantProperty(
  element: HTMLElement,
  propertyName: string,
  value: string
) {
  element.style.setProperty(propertyName, value, 'important')
}

export function fixBadLogoStyling(klarnaLogo: HTMLElement): string[] {
  const issues: string[] = []
  if (!klarnaLogo) return []

  const klarnaLogoStyle = window.getComputedStyle(klarnaLogo)
  const fontSizeStr = klarnaLogo.style.getPropertyValue('font-size')
    || klarnaLogoStyle.getPropertyValue('font-size')
  if (fontSizeStr) {
    const [, wholeNumber] = fontSizeStr.match(/^(0|[1-9]\d*)(\.\d+)?px$/)
    if (wholeNumber && Number(wholeNumber) < BASE_LOGO_FONT_SIZE) {
      issues.push(`font-size: [${fontSizeStr}]`)
      setImportantProperty(klarnaLogo, 'font-size', `${BASE_LOGO_FONT_SIZE}px`)
    }
  }

  const fontFamily = klarnaLogo.style.getPropertyValue('font-family')
    || klarnaLogoStyle.getPropertyValue('font-family')
  if (fontFamily && fontFamily !== BASE_LOGO_FONT_FAMILY) {
    issues.push(`font-family: [${fontFamily}]`)
    setImportantProperty(klarnaLogo, 'font-family', BASE_LOGO_FONT_FAMILY)
  }

  const fontWeight = klarnaLogo.style.getPropertyValue('font-weight')
    || klarnaLogoStyle.getPropertyValue('font-weight')
  if (fontWeight && !BASE_LOGO_FONT_WEIGHT.includes(fontWeight)) {
    issues.push(`font-weight: [${fontWeight}]`)
    setImportantProperty(klarnaLogo, 'font-weight', BASE_LOGO_FONT_WEIGHT[0])
  }

  return issues
}

export function hasBadgeHidden(badgeStyle: CSSStyleDeclaration): boolean {
  if (!badgeStyle) return false
  if (badgeStyle.display === 'none') return true
  if (badgeStyle.visibility === 'hidden') return true
  if (badgeStyle.opacity && parseFloat(badgeStyle.opacity) < 0.9) return true
  return false
}

export default function checkStylingIssues(element: HTMLElement): boolean {
  const shadowRoot = element.querySelector('div')?.shadowRoot

  if (!shadowRoot) {
    return
  }

  const klarnaBadges = shadowRoot.querySelectorAll('svg')
  const klarnaLogo = shadowRoot.querySelector(
    `[part=${OSM_LOGO_PART_NAME}]`
  ) as HTMLElement

  if (klarnaBadges.length) {
    const hasHiddenBadges = Array.from(klarnaBadges).every(klarnaBadge => {
      const klarnaBadgeStyle = window.getComputedStyle(klarnaBadge)
      return hasBadgeHidden(klarnaBadgeStyle)
    })

    if (hasHiddenBadges) {
      sendStylingIssueEvent('hidden-badge')
      warn(`
Klarna's logo should not be hidden in <klarna-placement>. 
Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/styling-on-site-messaging-with-css/#prerequisites`)
    }
  }

  const logoIssues = fixBadLogoStyling(klarnaLogo)
  if (logoIssues.length > 0) {
    sendStylingIssueEvent('hidden-logo', logoIssues)
    warn(`
Klarna's logo should not be hidden in <klarna-placement>. 
Please check https://docs.klarna.com/on-site-messaging/in-depth-knowledge/styling-on-site-messaging-with-css/#prerequisites`)
  }
}
