import config from '../../config'
import cookies from '../cookies/cookies'
import { generateSessionId } from '../generateSessionId/generateSessionId'

function setSession(sessionId: string) {
  cookies.save({
    name: config.COOKIE_SESSION_ID_NAME,
    value: sessionId,
    domain: window.location.hostname
  })
}

export function createSession(force = false): string {
  let sessionId
  if (force) {
    sessionId = generateSessionId()
  } else {
    const existingSessionId = cookies.getValue(config.COOKIE_SESSION_ID_NAME)
    sessionId = existingSessionId || generateSessionId()
  }

  setSession(sessionId)

  return sessionId
}
